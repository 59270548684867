body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-main-light {
  background-color: rgb(243, 243, 243);
  min-height: 100vh;
}

.bg-main-dark {
  background-color: rgb(99, 98, 98);
  min-height: 100vh;
}

.icon-light {
  filter: invert(1);
}

.icon-dark {
  filter: invert(0);
}

.nav-link-light {
  color: #fff;
}

.nav-link-dark {
  color: #000;
}

.nav-active {
  color: #6c757d;
  font-weight: bold;
  text-decoration: underline;
}

.table-column-btn {
  width: 1%;
}

.btn, .form-control {
  border-radius: 0;
}

.navBarShadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.form-switch .form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 0 0 #000;
}

.form-switch .form-check-input:checked {
  background-color: green;
  border: none;
}